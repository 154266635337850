
<template>

  <div class="text-center">
    <v-card @click="dialog=true">
      <v-row class="ma-0 h-full">
        <v-col cols="12" order="1" order-lg="2" class="memberpricing-bg">
          <div class="membership-pricing">
            <p class="mt-6 text--primary">
              <sub class="text-2xl">1{{MonedaDefault.simbolo}}=</sub>
              <sup class="font-weight-semibold">{{funciones.formatoNumeric(MonedaPais.tasa)}}</sup>
              <sub class="text-xl">{{MonedaPais.simbolo}}</sub>
            </p>
            <span class="my-6 text--secondary text-sm">Tasa actual</span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Tasas de cambio
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-select :items="monedas.filter(item=>(item.actual == false))" v-model="monedaSelect"
                  item-text="nombre" item-value="id" label="Moneda"></v-select>
              </v-col>
              <v-col>
                <v-text-field label="Tasa" v-model="tasa" :disabled="CambiandoTasa"
                :append-outer-icon="icons.mdiContentSave"
                  @click:append-outer="guardarTasa()" 
                  ></v-text-field>
              </v-col>

            </v-row>
            <v-row>
              <v-col>
                <div style=" height: 300px; overflow-y: auto;">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Fecha
                      </th>
                      
                      <th class="text-left">
                        Moneda
                      </th>
                      <th class="text-left">
                        tasa
                      </th>
                      <th colspan="2" class="text-center">
                        ind
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item,index) in historialTasas"
                      :key="index"
                    >
                      <td>{{ item.fecha }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>{{item.simbolo}} {{ funciones.formatoNumeric(item.tasa) }}</td>
                      <td><span :class="item.porcentaje >=0?   'success--text' : 'error--text'">{{funciones.formatoNumeric(item.porcentaje)}} %</span></td>
                      <td><v-icon :color="item.porcentaje >=0?   'success' : 'error'" > {{item.porcentaje >=0?   icons.mdiArrowUpThick :icons.mdiArrowDownThick  }}</v-icon>  </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiContentSave,
  mdiArrowDownThick ,
  mdiArrowUpThick 
} from '@mdi/js'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import funciones from '@/funciones/funciones'
export default {
  setup() {

    const dialog = ref(false)
    const monedas = ref([])
    const monedaSelect = ref({})
    const historialTasas = ref([])
    const tasa = ref(0)
    const MonedaDefault = ref({})
    const MonedaPais = ref({})
    const CambiandoTasa = ref(false)

    const guardarTasa =()=>{
      if (monedaSelect.value > 0 && tasa.value > 0){
        CambiandoTasa.value = true
        MaestroService.cambiarTasaCambio({id_empleado : store.state.user.id_empleado, id_moneda : monedaSelect.value,tasa:tasa.value})
        .then(response=>{
            if (response.data.mensaje == 'TASA GUARDADO CON EXITO') {
           
              cargarMonedas()
              cargarHistorialTasas()
              store.commit('setAlert', {
                message: "TASA GUARDADO CON EXITO",
                type: 'success',
              })
            }else{
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'info',
              })
            }
        })
        .catch(error=>{
            console.log(error)
        })
        .finally(item=>{
          CambiandoTasa.value = false
        })
      }else{
        store.commit('setAlert', {
                message: "Debe selecionar una moneda y asignarle un valor valido",
                type: 'info',
              })
        
      }
    
    }

    watch(monedaSelect, () => {
      monedas.value.forEach((item) => {
        if (item.id == monedaSelect.value) {
          tasa.value = item.tasa
        }
      })
    })

    watch(dialog, () => {
      if (dialog.value == true) {
        cargarMonedas()
        cargarHistorialTasas()

      }
    })

    onBeforeMount(() => {
      cargarMonedas()
      cargarHistorialTasas()
    })
    const cargarMonedas = () => {
      MaestroService.listarMonedas()
        .then(response => {
          monedas.value = response.data.datos  
          MonedaDefault.value = response.data.datos.filter(item=>(item.actual==true))[0]
          MonedaPais.value = response.data.datos.filter(item=>(item.meneda_pais==true))[0]
          monedaSelect.value = null
          tasa.value = 0
        })
        .then(error => {
          console.log(error)
        })
        .finally(iten => {

        })
    }


    const cargarHistorialTasas = () => {
      MaestroService.listarHistorialTasasMonedas()
        .then(response => {
          historialTasas.value = response.data.datos
        })
        .then(error => {
          console.log(error)
        })
        .finally(iten => {

        })
    }


    return {
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiContentSave,
        mdiArrowDownThick ,
  mdiArrowUpThick 
      
      },
      dialog,
      monedas,
      monedaSelect,
      historialTasas,
      tasa,
      guardarTasa,
      MonedaPais,
      MonedaDefault,
      CambiandoTasa,
      funciones
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 2.75rem;
    top: 9px;
  }
}
</style>

