
<template>
  <div>
    <v-tabs v-model="tab" centered>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab1" v-if="ver.includes('FORMA_PAGO')">
        Formas de pago
        <v-icon>mdi-phone</v-icon>
      </v-tab>

      <v-tab href="#tab2" v-if="ver.includes('VENTA')">
        Ventas
        <v-icon>mdi-heart</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item value="tab1" v-if="ver.includes('FORMA_PAGO')">
        <v-card flat>
          <v-simple-table dense height="250" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-uppercase">Locatario/area</th>
                  <th class="text-left text-uppercase">Moneda</th>
                  <th class="text-left text-uppercase">Forma Pago</th>
                  <th class="text-right text-uppercase">Monto Moneda</th>
                  <th class="text-right text-uppercase">Monto $</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in datos.ventas_puesto_formas_pago" :key="index">
                  <td>{{ item.nombre_puesto }}</td>
                  <td>{{ item.nombre_moneda }}</td>
                  <td>{{ item.nombre_tipo_pago }}</td>
                  <td class="text-right">{{ item.simbolo }}.{{ funciones.formatoNumeric(item.pago_moneda) }}</td>
                  <td class="text-right">$.{{ funciones.formatoNumeric(item.pago_real) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab2" v-if="ver.includes('VENTA')">
        <v-card flat>
          <v-simple-table dense height="250" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-uppercase">Locatario/area</th>

                  <th class="text-right text-uppercase">Total venta</th>
                  <th class="text-right text-uppercase">Servicio</th>
                  <th class="text-right text-uppercase">Total pagado</th>
                  <th class="text-right text-uppercase">Total por pagar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in datos.ventas_puesto" :key="index">
                  <td>{{ item.nombre }}</td>
                  <td class="text-right">${{ funciones.formatoNumeric(item.total_ventas_netas) }}</td>
                  <td class="text-right">${{ funciones.formatoNumeric(item.total_servicios) }}</td>
                  <td class="text-right">${{ funciones.formatoNumeric(item.total_por_pagadas) }}</td>
                  <td class="text-right">${{ funciones.formatoNumeric(item.total_por_pagar) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import funciones from '@/funciones/funciones'

import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  props: {
    id_fecha: String,
    id_grupo: {
      type: Number,
      default: -1,
    },
    id_puesto: {
      type: Number,
      default: -1,
    },
    ver: {
      type: Object,
      default: ['VENTA', 'FORMA_PAGO'],
    },
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.cargarDatos(false)
        }, 6000)
      }
    },
  },
  setup(props) {
    const datos = ref([])
    const tab = ref('tab1')
    let counterConsultas = ref(0)

    onBeforeMount(() => {
      setTimeout(() => {
        cargarDatos(true)
      }, 1000)
    })
    watch(props, () => {
      setTimeout(() => {
          //alert("modificarComandaAll")
          cargarDatos(false)
        }, 6000)
    })
    /*
    socket.on('modificar-comanda-all', (...args) => {
      setTimeout(() => {
        cargarDatos(false)
      }, 5000)
    })*/
    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.puestoPorFormasPagos({
          id_fecha: props.id_fecha,
          id_grupo: props.id_grupo,
          id_puesto: props.id_puesto,
        })
          .then(response => {
            datos.value = response.data.datos
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }

    return {
      datos,
      store,
      tab,
      funciones,
      cargarDatos,
      stateSocket,
    }
  },
}
</script>
