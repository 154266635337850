
<template>
  <div>
    <v-simple-table dense height="250" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-uppercase">Moneda</th>
            <th class="text-left text-uppercase">Tipo</th>
            <th class="text-right text-uppercase">Venta</th>
            <th class="text-right text-uppercase">Propina</th>
            <th class="text-right text-uppercase">Total</th>
            <th class="text-right text-uppercase">$</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in datos" :key="index">
            <td class="text-left">
              {{ item.moneda }}
            </td>
            <td class="text-left">
              {{ item.tipo_pago }}
            </td>
            <td class="text-right">{{ item.simbolo }}.{{ funciones.formatoNumeric(item.pago_moneda) }}</td>
            <td class="text-right">{{ item.simbolo }}.{{ funciones.formatoNumeric(item.pago_moneda_propina) }}</td>
            <td class="text-right">{{ item.simbolo }}.{{ funciones.formatoNumeric(item.pago_moneda_total) }}</td>
            <td class="text-right">${{ funciones.formatoNumeric(item.pago_real_total) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="grey4">
            <td colspan="5">TOTAL</td>
            <td class="text-right">{{ funciones.formatoNumeric(totales.pago_real_total) }}</td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  props: {
    id_fecha: String,
    id_grupo: Number,
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.cargarDatos(false)
        }, 9000)
      }
    },
  },
  setup(props) {
    const datos = ref([])

    let counterConsultas = ref(0)

    onBeforeMount(() => {
      setTimeout(() => {
        cargarDatos(true)
      }, 9000)
    })
    watch(props, () => {
      setTimeout(() => {
          //alert("modificarComandaAll")
          cargarDatos(false)
        }, 9000)
    })
    /*socket.on('modificar-comanda-all', (...args) => {
      setTimeout(() => {
        cargarDatos(false)
      }, 7000)
    })*/
    const totales = ref({
      pago_moneda: 0,
      pago_moneda_propina: 0,
      pago_moneda_total: 0,
      pago_real: 0,
      pago_real_propina: 0,
      pago_real_total: 0,
    })

    const totalizar = () => {
      totales.value = {
        pago_moneda: 0,
        pago_moneda_propina: 0,
        pago_moneda_total: 0,
        pago_real: 0,
        pago_real_propina: 0,
        pago_real_total: 0,
      }
      datos.value?.forEach(item => {
        totales.value.pago_moneda += item.pago_moneda
        totales.value.pago_moneda_propina += item.pago_moneda_propina
        totales.value.pago_moneda_total += item.pago_moneda_total
        totales.value.pago_real += item.pago_real
        totales.value.pago_real_propina += item.pago_real_propina
        totales.value.pago_real_total += item.pago_real_total
      })
    }
    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.reporteTotalizadoCaja({ id_fecha: props.id_fecha, id_grupo: props.id_grupo })
          .then(response => {
            datos.value = response.data.datos
            totalizar()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }

    return {
      funciones,
      datos,
      totales,
      store,
      cargarDatos,
      stateSocket,
    }
  },
}
</script>
