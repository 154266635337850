<template>
  <v-row class="py-0 my-0">
    <v-col cols="12" class="py-0 my-0">
      <v-row class="match-height">
        <v-col cols="12" lg="6" md="6" sm="12">
          <statistics-card-vertical
            :change="''"
            color="success"
            :icon="icons.mdiPoll"
            :statistics="`$${funciones.formatoNumeric(
              datos.venta_global + datos.servicio2 + datos.iva2 + datos.propinas + datos.igtf,
            )}`"
            stat-title="Arqueo General "
            :subtitle="`Propina: $${datos.propinas == undefined ? 0 : funciones.formatoNumeric(datos.propinas)}`"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <statistics-card-vertical
            :change="''"
            color="primary2"
            :icon="icons.mdiPoll"
            :statistics="`$${funciones.formatoNumeric(datos.venta_global + datos.servicio2 + datos.iva2 + datos.igtf)}`"
            stat-title="Ventas + Iva +Igtf"
            :subtitle="`IVA: $${funciones.formatoNumeric(datos.iva2)} IGTF: $${funciones.formatoNumeric(datos.igtf)}`"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="primary"
            :icon="icons.mdiPoll"
            :statistics="`$${funciones.formatoNumeric(datos.venta_global + datos.servicio2)}`"
            stat-title="Venta base"
            :subtitle="`Sin 10%: $${funciones.formatoNumeric(datos.venta_global)}`"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="info"
            :icon="icons.mdiCurrencyUsd"
            :statistics="`$${funciones.formatoNumeric(datos.pago_real)}`"
            stat-title="Comandas pagadas"
            :subtitle="`Por Pagar: $${funciones.formatoNumeric(datos.pendientes)}`"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="primary5"
            :icon="icons.mdiCurrencyUsd"
            :statistics="`$${funciones.formatoNumeric(datos.servicio2)}`"
            stat-title="Servicio"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="warning"
            :icon="icons.mdiCurrencyUsd"
            :statistics="`$${funciones.formatoNumeric(datos.porcentaje_general)}`"
            stat-title="Balance General"
            :subtitle="`Pagado: $${funciones.formatoNumeric(datos.porcentaje_general_pago)}`"
          >
          </statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import MaestroService from '@/api/servicios/MaestroService'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'
// demos

export default {
  props: {
    id_fecha: String,
    id_grupo: Number,
  },
  components: {
    StatisticsCardVertical,
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        if (this.id_fecha != '2000-01-01') {
          this.ConsultarDatos(false)
        }
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        if (this.id_fecha != '2000-01-01') {
          setTimeout(() => {
            //alert("modificarComandaAll")
            this.ConsultarDatos(false)
          }, 1000)
        }
      }
    },
  },
  setup(props) {
    const datos = ref({
      id_grupo_negocio: 1,
      descripcion: '',
      venta_global: 0,
      venta_global_con_porcentaje: 0,
      venta_global_con_porcentaje_iva: 0,
      iva_global: 0,
      pago_real: 0,
      pendientes: 0,
      porcentaje_global: 0,
      porcentaje_global_evitado: 0,
      porcentaje_general: 0,
      porcentaje_general_pago: 0,
      propinas: 0,
      venta_global: 0,
      servicio2: 0,
      iva2: 0,
      propinas: 0,
      igtf: 0,
    })

    let counterConsultas = ref(0)

    onBeforeMount(() => {
      if (props.id_fecha != '2000-01-01') {
      setTimeout(() => {
        ConsultarDatos(true)
      }, 1000)
    }
    })

    watch(props, () => {
      if (props.id_fecha != '2000-01-01') {
      ConsultarDatos(false)
      }
    })
    /*
    socket.on('modificar-comanda-all', (...args) => { 
      setTimeout(() => {
        ConsultarDatos(false)
      }, 1000)
    })*/

    const ConsultarDatos = actualizar => {
      
      MaestroService.reporteDatosVentasGeneral({ id_fecha: props.id_fecha, id_grupo: props.id_grupo })
        .then(response => {
          console.log(response.data)
          datos.value = response.data.datos
        })
        .catch(error => {
          console.log(error)
        })
        .finally(dato => {
          if (actualizar == true) counterConsultas.value += 1
        })
    }

    const totalProfit = {
      statTitle: 'Ventas general',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Sin 10%: $9,384.27',
      statistics: '$9,953.25',
      change: '',
    }

    const totalSales = {
      statTitle: 'Comandas pagadas',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Por Pagar: $0.00',
      statistics: '$9,953.25',
      change: '',
    }

    // vertical card options
    const newProject = {
      statTitle: 'Servicio',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: '% Evitado: $369.45',
      statistics: '$568.9',
      change: '',
    }

    const salesQueries = {
      statTitle: 'Balance General',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Pagado: $5,568.39',
      statistics: '$5,568.39',
      change: '',
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
      datos,
      icons: {
        mdiPoll,
        mdiLabelVariantOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
      },
      funciones,
      stateSocket,
      ConsultarDatos,
    }
  },
}
</script>
    