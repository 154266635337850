
<template>
  <div height="300px">
    <div v-for="(caja, index) in datos" :key="index">
      <v-subheader class="secondary"
        ><span class="white--text">{{ caja.nombre }}</span></v-subheader
      >
      <div></div>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-uppercase secondary">
                <span class="white--text">Tipo Pago</span>
              </th>
              <th class="text-right text-uppercase secondary">
                <span class="white--text">Monto Moneda</span>
              </th>
              <th class="text-right text-uppercase secondary">
                <span class="white--text">Monto $</span>
              </th>
            </tr>
          </thead>
          <tbody v-for="(moneda, index) in caja.monedas" :key="index">
            <tr class="secondary">
              <td class="text--white text-center" colspan="3">
                <span class="white--text">{{ moneda.nombre }}</span>
              </td>
            </tr>
            <tr v-for="(tipoPago, index) in moneda.tipo_pago" :key="index">
              <td class="text-left">
                {{ tipoPago.nombre_tipo }}
              </td>
              <td class="text-right">{{ moneda.simbolo }} {{ funciones.formatoNumeric(tipoPago.pago_moneda) }}</td>
              <td class="text-right">$ {{ funciones.formatoNumeric(tipoPago.pago_real) }}</td>
            </tr>
            <tr class="grey4">
              <td class="text--white">TOTAL {{ moneda.simbolo }}</td>
              <td class="text-right">{{ moneda.simbolo }} {{ funciones.formatoNumeric(moneda.pago_moneda) }}</td>
              <td class="text-right white--text">${{ funciones.formatoNumeric(moneda.pago_real) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="primary4">
              <td class="text--white" colspan="2">
                <span class="white--text">TOTAL {{ caja.nombre }}</span>
              </td>
              <td class="white--text text-right">
                <span class="white--text">${{ funciones.formatoNumeric(caja.pago_real) }}</span>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import { json2excel, excel2json } from 'js2excel'
import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  props: {
    id_fecha: String,
  },
  components: {},
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        if (this.id_fecha != '2000-01-01') {
          this.consultarArqueo(false)
        }
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        if (this.id_fecha != '2000-01-01') {
          setTimeout(() => {
            //alert("modificarComandaAll")
            this.consultarArqueo(false)
          }, 3000)
        }
      }
    },
  },
  setup(props) {
    const datos = ref([])
    let counterConsultas = ref(0)
    onBeforeMount(() => {
      if (props.id_fecha != '2000-01-01') {
        setTimeout(() => {
          consultarArqueo(true)
        }, 3000)
      }
    })

    let data = [
      {
        userId: 1,
        userPhoneNumber: 1888888888,
        userAddress: 'xxxx',
        date: '2013/09/10 09:10', // string
      },
      {
        userId: 2,
        userPhoneNumber: 1888888888,
        userAddress: 'xxxx',
        date: new Date(),
      },
      {
        userId: 3,
        userPhoneNumber: 1888888888,
        userAddress: 'xxxx',
        date: new Date(),
      },
    ]

    const exportarData = () => {
      try {
        json2excel({
          data: datos.value,
          name: 'user-info-data',
          formateDate: 'yyyy/mm/dd',
        })
      } catch (e) {
        console.error('export error')
      }
    }
    /*
    socket.on('modificar-comanda-all', (...args) => {
       
      setTimeout(() => {
        consultarArqueo(false)
      }, 3000)
    })*/

    watch(props, () => {
      if (props.id_fecha != '2000-01-01') {
        consultarArqueo(false)
      }
    })

    const consultarArqueo = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.reporteDatosArqueoCaja({ id_fecha: props.id_fecha })
          .then(response => {
            console.log(response.data)
            datos.value = response.data.datos
          })
          .catch(error => {
            console.log(error)
          })
          .finally(dato => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }

    return {
      datos,
      funciones,
      exportarData,
      stateSocket,
      consultarArqueo,
    }
  },
}
</script>
