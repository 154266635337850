<template>
  <div>
    <v-card>
      <v-card-title>
        Comandas   <v-btn  icon x-small  :loading="cargando" @click="cargarDatos(false)" > <v-icon>{{ icons.mdiReload }}</v-icon> </v-btn>
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              class="mr-2"
              :items="filters"
              item-text="descripcion"
              item-value="id"
              label="Filtro"
              dense
              outlined
              v-model="selectFiltro"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              dense
              outlined
              hide-details
              single-line
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <!--
      <v-data-table height="400" :headers="headers" :items="filtarDatos()" :items-per-page="-1" :search="search"
        :expanded.sync="expanded" show-expand>
        <template #[`item.estado_pago`]="{ item }">
          <div class="text-left">
            <v-icon small :color="estado_comanda.find(it => (item.estado_pago == it.id)).color">{{ estado_comanda.find(it
              => (item.estado_pago == it.id)).icon
            }}</v-icon>
          </div>
        </template>

        <template #[`item.id`]="{ item }">
          <div class="text-left">
            <small>{{ item.id }}</small>
          </div>
        </template>

        <template #[`item.cliente`]="{ item }">
          <div class="text-left">
            <small>{{ item.cliente }}</small>
            <br>
            <small v-if="item.id_cliente > 0"> <strong>{{ FuncionesGenerales.camelCase(item.nombre_cliente) }} {{
              FuncionesGenerales.camelCase(item.apellido_cliente)
            }}</strong>
            </small>
          </div>
        </template>
        <template #[`item.pagos`]="{ item }">
          <div class="text-left" v-for="(item2, index) in item.pago" :key="index">

            <v-chip :color="statusColor[item2.simbolo + item2.tipo_pago_nombre]" :class="`white--text`"
              class="v-chip-light-bg" x-small>
              {{ item2.simbolo }} {{ item2.tipo_pago_nombre }} {{ item2.pago_moneda }}
            </v-chip>

          </div>
        </template>
        <template #[`item.nombre_mesonero`]="{ item }">
          <div class="text-left">
            <small>{{ FuncionesGenerales.camelCase(item.nombre_mesonero) }}</small>
          </div>
        </template>
        <template #[`item.accion`]="{ item }">
          <v-btn-toggle dense group>
            <v-btn small @click="AbrirModalComanda(item)">
              <v-icon color="info" small>{{ icons.mdiMagnify }}</v-icon>
            </v-btn>

            <v-btn small :disabled="item.estado_pago == 14"
              @click="anularComanda({ id_comanda: item.id, id_empleado: store.state.user.id_empleado }, item)">
              <v-icon color="error" small>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>

            <v-btn small
              :disabled="item.estado_pago == 14 || item.estado_pago == 9 || item.cargandobloquearDesbloquearComanda"
              :loading="item.cargandobloquearDesbloquearComanda" @click="bloquearDesbloquearComanda(item)">
              <v-icon v-if="item.ind_bloqueado" small color="warning">{{ icons.mdiBlockHelper }}</v-icon>
              <v-icon v-else small color="warning">{{ icons.mdiCheckBold }}</v-icon>
            </v-btn>

            <v-btn small :disabled="item.estado_pago == 14 || item.estado_pago == 9">
              <v-icon small color="info">{{ icons.mdiAccount }}</v-icon>
            </v-btn>
            <v-btn small @click="modalTransferirAbrir(item)">
              <v-icon small color="info">{{ icons.mdiSwapHorizontal }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
      

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <p>
              Comandero: {{ item.nombre_comandero }}
            </p>
            <p>Sub Total: {{ item.subtotal }}</p>
            <p>Servicio: {{ item.comision }}</p>
            <p>Total: {{ item.total }}</p>
            <p>Pago: {{ item.total_pago }}</p>
            <p>Fiscalizado: {{ item.descripcion_estado_fiscalizado }}</p>

          </td>
        </template>

      </v-data-table>
      -->
      <v-simple-table fixed-header dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>-</th>
              <th>N°</th>
              <th>CLIENTE</th>
              <th>COMANDERO</th>
              <th>MESONERO</th>
              <th>CAJERO</th>
              <th>IVA</th>
              <th>TOTAL</th>
              <th>PAGOS</th>
              <th>ACCION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, y) in filtarDatos()" :key="y">
              <td>
                <div class="text-left">
                  <v-icon small :color="estado_comanda(item).color">{{ estado_comanda(item).icon }}</v-icon>
                </div>
              </td>
              <td>
                <div class="text-left">
                  <small
                    >{{ item.id }}
                    <br v-if="item.id_comanda_union" />
                    <small v-if="item.id_comanda_union">Unida -> {{ item.id_comanda_union }}</small>
                    <small v-if="item.doc_integracion" ><br> {{ item.doc_integracion}} </small>
                  </small>
                </div>
              </td>
              <td>
                <div class="text-left">
                  <small v-if="item.cliente">{{ item.cliente }}</small>
                  <br v-if="item.cliente" />
                  <small v-if="item.id_cliente > 0">
                    <strong
                      >{{ FuncionesGenerales.camelCase(item.nombre_cliente) }}
                      {{ FuncionesGenerales.camelCase(item.apellido_cliente) }}</strong
                    >
                  </small>
                </div>
              </td>
              <td>
                <div class="text-left">
                  <small>{{ FuncionesGenerales.camelCase(item.nombre_comandero) }}</small>
                </div>
              </td>
              <td>
                <div class="text-left">
                  <small>{{ FuncionesGenerales.camelCase(item.nombre_mesonero) }}</small>
                </div>
              </td>
              <td>
                <div>
                  <small v-if="item.estado_pago == 9 && item.pago">
                    {{ FuncionesGenerales.camelCase(item.pago[0].nombre_cajero) }}
                  </small>
                </div>
              </td>
              <td>
                <div class="text-left">
                  <small class="error--text" v-if="item.ind_exonerar_iva">Exonerado</small>
                  <small v-else>{{ FuncionesGenerales.formatoNumeric(item.iva) }}</small>
                </div>
              </td>
              <td>
                <div class="text-left">
                  <small>{{ FuncionesGenerales.formatoNumeric(item.total) }}</small>
                </div>
              </td>

              <td>
                <div class="text-left" v-for="(item2, index) in item.pago" :key="index">
                  <v-chip
                    :color="statusColor[item2.simbolo + item2.tipo_pago_nombre]"
                    :class="`white--text`"
                    class="v-chip-light-bg"
                    x-small
                  >
                    {{ item2.simbolo }} {{ item2.tipo_pago_nombre }} {{ item2.pago_moneda }}
                  </v-chip>
                </div>
              </td>
              <td>
                <v-btn icon small @click="AbrirModalComanda(item)">
                  <v-icon color="info" small>{{ icons.mdiMagnify }}</v-icon>
                </v-btn>

                <v-btn
                  icon
                  small
                  v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA) && item.estado_pago != 14"
                  @click="anularComanda({ id_comanda: item.id, id_empleado: store.state.user.id_empleado }, item)"
                >
                  <v-icon color="error" small>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>

                <v-btn
                  icon
                  small
                  v-if="!(item.estado_pago == 14 || item.estado_pago == 9 || item.cargandobloquearDesbloquearComanda)"
                  :loading="item.cargandobloquearDesbloquearComanda"
                  @click="bloquearDesbloquearComanda(item)"
                >
                  <v-icon v-if="item.ind_bloqueado" small color="warning">{{ icons.mdiBlockHelper }}</v-icon>
                  <v-icon v-else small color="warning">{{ icons.mdiCheckBold }}</v-icon>
                </v-btn>

                <v-btn icon small v-if="!(item.estado_pago == 14 || item.estado_pago == 9)">
                  <v-icon small color="info">{{ icons.mdiAccount }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="modalTransferirAbrir(item)"
                  v-if="item.estado_pago != 14 && item.estado_pago == 7"
                >
                  <v-icon small color="info">{{ icons.mdiSwapHorizontal }}</v-icon>
                </v-btn>
                <v-btn v-if="item.estado_pago == 14" icon small @click="ImprimirAnulacionCompleta(item)">
                  <v-icon small color="info">{{ icons.mdiPrinter }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-pagination
        total-visible="7"
        v-model="page_number"
        :length="Number(no_of_pages == 0 ? 1 : no_of_pages)"
        circle
      ></v-pagination>
    </v-card>
    <modal-ver-comanda-vue
      :comanda="comandaSelect"
      :soloVer="false"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
      :monedas="monedas"
    >
    </modal-ver-comanda-vue>
    <modal-transferir-comanda-vue
      :comanda="comandaSelect"
      :modalTransferir="modalTransferir"
      @GetModalTransferir="GetModalTransferir"
    >
    </modal-transferir-comanda-vue>
    <ImpresoraSelect ref="ImpresoraSelectRef" @getImpresora="getImpresora" />
  </div>
</template>

<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import {
  mdiSquareEditOutline,
  mdiSwapHorizontal,
  mdiDotsVertical,
  mdiReload,
  mdiCheckAll,
  mdiBlockHelper,
  mdiDeleteOutline,
  mdiMagnify,
  mdiAccount,
  mdiCheckBold,
  mdiCallMerge,
  mdiPrinter,
} from '@mdi/js'
import data from './datatable-data'
import MaestroService from '@/api/servicios/MaestroService'
import ModalVerComandaVue from './ModalVerComanda.vue'
import store from '@/store'
import Vue from 'vue'
import ComandaService from '@/api/servicios/ComandaService'
import FuncionesGenerales from '@/funciones/funciones'
import ModalTransferirComandaVue from '@/views/sistema/comanda/dashboard/componentes/ModalTransferirComanda.vue'
import { acciones } from '@/modulos'
import ImpresoraSelect from '@/views/sistema/comanda/dashboard/componentes/ImpresoraSelect.vue'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  components: { ModalVerComandaVue, ModalTransferirComandaVue, ImpresoraSelect },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.cargarDatos(false)
        }, 1000)
      }
    },
  },
  props: {
    id_fecha: String,
  },
  setup(props) {
    const statusColor = {
      /* eslint-disable key-spacing */
      $EFECTIVO: 'success',
      $CREDITO: 'primary2',
      $DEBITO: 'primary3',
      $ZELLE: 'info',
      BSEFECTIVO: 'primary4',
      BSDEBITO: 'primary2',
      BSCREDITO: 'primary3',
      'OtPOR COBRAR': 'error',
      /* eslint-enable key-spacing */
    }
    const modalTransferir = ref(false)
    const cargarCount = ref(0)
    const comandaSelect = ref({})
    const expanded = ref([])
    const search = ref('')
    const selectFiltro = ref(2)
    const ultimaActualizacion = ref('20200101')
    const datos = ref([])
    const openModalComanda = ref(false)
    const cargandobloquearDesbloquearComanda = ref(false)
    const ImpresoraSelectRef = ref(null)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const modalTransferirAbrir = item => {
      comandaSelect.value = item
      modalTransferir.value = true
    }
    const AbrirModalComanda = datos => {
      openModalComanda.value = true
      comandaSelect.value = datos
    }
    const GetModalTransferir = datos => {
      modalTransferir.value = datos
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const ImprimirAnulacionCompleta = item => {
      ImpresoraSelectRef.value.abrir(item)
    }
    const getImpresora = item => {
      ImprimirAnulacionCompletaDatos(item)
    }
    onBeforeMount(() => {
      cargarMonedas()
    })
    const monedas = ref([])
    const cargarMonedas = () => {
      MaestroService.listarMonedas()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            monedas.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const ImprimirAnulacionCompletaDatos = item => {
      item.imprimiendo = true
      ComandaService.ImprimirAnulacion({
        id_comanda: item.id,
        id_comanda_detalle: 0,
        id_grupo_impresora_pc: item.id_grupo_impresora_pc,
      })
        .then(response => {
          if (response.data.mensaje == 'guardado con exito') {
            store.commit('setAlert', {
              message: 'Se envio a la impresora',
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'Error ' + error,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'ImprimirAnulacionCompletaDatos',
          })
        })
        .finally(() => {
          item.imprimiendo = false
        })
    }

    const filters = ref([
      { id: 1, descripcion: 'TODOS' },
      { id: 2, descripcion: 'PENDIENTES' },
      { id: 3, descripcion: 'PAGADAS' },
      { id: 4, descripcion: 'ANULADAS' },
      { id: 5, descripcion: 'FISCALIZADAS' },
      { id: 6, descripcion: 'CUENTAS POR COBRAR' },
    ])
    const estado_comanda = item => {
      if (item.estado_pago == 7) {
        return { id: 7, icon: mdiReload, color: 'warning' }
      } else if (item.estado_pago == 8) {
        return { id: 8, icon: mdiBlockHelper, color: 'error' }
      } else if (item.estado_pago == 14) {
        if (item.id_comanda_union) {
          return { id: 14, icon: mdiCallMerge, color: 'error' }
        }
        return { id: 14, icon: mdiBlockHelper, color: 'error' }
      } else if (item.estado_pago == 9) {
        return { id: 9, icon: mdiCheckAll, color: 'success' }
      } else {
        return { id: 9, icon: mdiCheckAll, color: 'success' }
      }
    }
    const page_number = ref(1)
    const no_of_pages = ref(1)
    const no_comanda = ref(0)

    const filtarDatos = () => {
      var valor = datos.value?.filter(
        item =>
          ((item.estado_pago == item.estado_pago && selectFiltro.value == 1) ||
            (item.estado_pago == 7 && selectFiltro.value == 2) ||
            ((item.estado_pago == 8 || item.estado_pago == 14) && selectFiltro.value == 4) ||
            (item.estado_pago == 9 && selectFiltro.value == 3) ||
            (item.estado_fiscalizado == 40 && selectFiltro.value == 5) ||
            (item.estado_pago == 9 && selectFiltro.value == 6 && item.pago.find(it => it.tipo_pago == 10)) || search.value != '')    
      )

      valor = valor?.filter(
        item =>
        ( (  item.nombre_mesonero?.toUpperCase().includes(search.value.toUpperCase())) ||
          item.nombre_cliente?.toUpperCase().includes(search.value.toUpperCase()) ||
          item.apellido_cliente?.toUpperCase().includes(search.value.toUpperCase()) ||
          item.cliente?.toUpperCase().includes(search.value.toUpperCase()) ||
          item.id.toString().includes(search.value.toUpperCase()) ||
          (item.pago ? item.pago[0]?.nombre_cajero?.toUpperCase().includes(search.value.toUpperCase()) : false) || 
          search.value == '') 
      ) 

      no_comanda.value = valor?.length
      const total_no_of_items = valor?.length
      const items_per_page = 8
      no_of_pages.value =
        total_no_of_items / items_per_page > Number((total_no_of_items / items_per_page).toFixed())
          ? Number((total_no_of_items / items_per_page).toFixed()) + 1
          : (total_no_of_items / items_per_page).toFixed()
      const items_to_skip = (page_number.value - 1) * items_per_page
      const items = valor?.slice(items_to_skip, items_per_page + items_to_skip)

      return items
    }

    let counterConsultas = ref(0)
    /*
    onBeforeMount(() => {
      setTimeout(() => {
        ultimaActualizacion.value = '20200101'
        cargarDatos(false)
      }, 1000)
    })*/
    /*
    socket.on('modificar-comanda-all', (...args) => {
      
      setTimeout(() => {
        cargarDatos(false)
      }, 3000)
    })
*/
    watch(props, () => {
      ultimaActualizacion.value = '20200101'
      cargarDatos(false)
    })

    const bloquearDesbloquearComanda = item => {
      item.cargandobloquearDesbloquearComanda = true
      cargarCount.value = cargarCount.value + 1
      ComandaService.BloquearDesbloquearComanda({ id_comanda: item.id })
        .then(response => {
          if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
            item.ind_bloqueado = !item.ind_bloqueado
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'error ' + error,
            type: 'error',
            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'bloquearDesbloquearComanda',
          })
        })
        .finally(() => {
          item.cargandobloquearDesbloquearComanda = false
          cargarCount.value = cargarCount.value + 1
        })
    }

    const anulandoComanda = ref(false)
    const anularComanda = (datos, item) => {
      anulandoComanda.value = true
      Vue.swal({
        icon: 'warning',
        html:
          '<h3>¿Seguro que quiere anular la comanda ? </h3>' +
          `<p># ${item.id}</p> <br>` +
          ` 
          <textarea  id="swal-motivo-anulacion"   class="swal2-textarea" placeholder="Motivo anulación"></textarea>   `,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          const motivo = document.getElementById('swal-motivo-anulacion').value
          console.log(motivo)

          if (motivo != undefined && motivo != '' && motivo != null) {
            return ComandaService.anularComanda({
              id_comanda: item.id,
              id_operador: store.state.user.id_empleado,
              motivo: motivo,
            })
              .then(response => {
                if (response.data.mensaje == 'COMANDA ANULADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: response.data.mensaje,
                  })
                  anulandoComanda.value = false
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                anulandoComanda.value = false
                return false
              })
          } else {
            Vue.swal.showValidationMessage(`Debe proporcionar un motivo de anulacion`)
          }
        },
        allowOutsideClick: () => !Vue.swal.isLoading(),
      }).then(result => {
        anulandoComanda.value = false
      })
    }

    const cargando = ref(false)

    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        cargando.value = true
        MaestroService.reporteListarComandas({
          id_fecha: props.id_fecha,
          ultima_actualizacion: ultimaActualizacion.value,
        })

          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (ultimaActualizacion.value == '20200101') {
                datos.value = response.data.datos.comandas
              } else {
                if (response.data.datos.comandas != null) {
                  response.data.datos.comandas.forEach((element, i) => {
                    const index = datos.value.indexOf(
                      datos.value.find(item => item.id === response.data.datos.comandas[i].id),
                    )

                    if (index == -1) {
                      datos.value.push(response.data.datos.comandas[i])
                    } else {
                      datos.value.splice(index, 1, response.data.datos.comandas[i])
                    }
                  })
                }
              }

              ultimaActualizacion.value = response.data.datos.ultima_actualizacion
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
            cargando.value = false
          })
      }
    }

    return {
      headers: [
        { text: '-', value: 'estado_pago' },
        { text: 'N°', value: 'id' },
        { text: 'CLIENTE', value: 'cliente' },
        { text: 'MESONERO', value: 'nombre_mesonero' },
        { text: 'CAJERO', value: 'nombre_mesonero' },
        { text: 'TOTAL', value: 'total' },
        { text: 'PAGOS', value: 'pagos' },
        { text: 'ACCION', value: 'accion' },
      ],
      userList: data,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      statusColor,
      expanded,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiMagnify,
        mdiBlockHelper,
        mdiAccount,
        mdiCheckBold,
        mdiSwapHorizontal,
        mdiCallMerge,
        mdiPrinter,
        mdiReload
      },
      search,
      selectFiltro,
      filters,
      cargarDatos,
      datos,
      estado_comanda,
      filtarDatos,
      comandaSelect,
      GetOpenModalComanda,
      openModalComanda,
      AbrirModalComanda,
      anulandoComanda,
      anularComanda,
      store,
      bloquearDesbloquearComanda,
      cargarCount,
      FuncionesGenerales,
      modalTransferir,
      GetModalTransferir,
      modalTransferirAbrir,
      page_number,
      no_of_pages,
      no_comanda,
      validarAcciones,
      acciones,
      ImprimirAnulacionCompleta,
      getImpresora,
      ImpresoraSelectRef,
      stateSocket,
      monedas,
      cargando
    }
  },
}
</script>