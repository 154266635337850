
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-select
          hide-details=""
          class="mx-4"
          v-model="caja_select"
          :items="cajas"
          label="Caja"
          item-text="nombre_caja"
          item-value="id_caja"
          dense
          outlined
        >
        </v-select>
      </v-col>

      <v-col cols="12">
        <v-simple-table dense height="300" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-uppercase">Locat/area</th>
                <th class="text-left text-uppercase">Producto</th>
                <th class="text-right text-uppercase">Cantidad</th>
                <th class="text-right text-uppercase">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datos" :key="index">
                <td class="text-left">
                  {{ item.puesto_nombre }}
                </td>
                <td class="text-left">
                  {{ item.producto_nombre }}
                </td>
                <td class="text-right">
                  {{ item.cantidad }}
                </td>
                <td class="text-right">$.{{ funciones.formatoNumeric(item.total) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  props: {
    id_fecha: String,
    id_grupo: Number,
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.cargarDatos(false)
        }, 10000)
      }
    },
  },
  setup(props) {
    const datos = ref([])

    let counterConsultas = ref(0)
    const caja_select = ref(null)
    onBeforeMount(() => {
      setTimeout(() => {
        cargarCajas()
      }, 8000)
    })
    watch(props, () => {
      setTimeout(() => {
        //alert("modificarComandaAll")
        cargarDatos(false)
        cargarCajas()
      }, 10000)
    })
    /*socket.on('modificar-comanda-all', (...args) => {
      setTimeout(() => {
        cargarDatos(false)
      }, 8000)
    })*/

    watch(caja_select, () => {
      cargarDatos(false)
    })

    const total = ref(0)
    const totalizar = () => {
      total.value = 0
      datos.value?.forEach(item => {
        total.value += item.total
      })
    }

    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.rptVentaCajaProducto({ id_fecha: props.id_fecha, id_caja: caja_select.value })
          .then(response => {
            datos.value = response.data.datos
            totalizar()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }
    const cajas = ref([])

    const cargarCajas = () => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.cajasActivasDia({ id_fecha: props.id_fecha, id_grupo: props.id_grupo })
          .then(response => {
            cajas.value = response.data.datos
            // cargarDatos(true)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {})
      }
    }

    return {
      datos,
      store,
      caja_select,
      cajas,
      total,
      funciones,
      cargarDatos,
      stateSocket,
    }
  },
}
</script>
