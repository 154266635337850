
<template>
  <v-card>
    <v-card-text class="d-flex flex-column">
      <v-row>
        <v-col sm="6" lg="6">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="primary">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Total Comandas</span> <br />
          <strong class="ml-4"> {{ datos.total_comandas }}</strong>
        </v-col>
        <v-col sm="6" lg="6">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="error">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Total Anuladas</span> <br />
          <strong class="ml-4"> {{ datos.comandas_anuladas }}</strong>
        </v-col>
        <v-col sm="6" lg="6">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="success">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Total Pagadas</span> <br />
          <strong class="ml-4"> {{ datos.comandas_pagadas }}</strong>
        </v-col>
        <v-col sm="6" lg="6">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="info">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Total Pendientes</span> <br />
          <strong class="ml-4"> {{ datos.comandas_pendientes }}</strong>
        </v-col>
      </v-row>
    </v-card-text>
    <div class="text-center">
      <v-card-text>
        <v-btn color="error" class="mt-4" x-small :disabled="!datos.ind_cerrar" @click="CerrarDia()">
          Cerrar dia
        </v-btn>
        <br />
        <small>Para cerrar el dia no deben de haber comandas pendientes</small>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiCheckboxBlankCircle,
} from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService'
import Vue from 'vue'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { socket, state as stateSocket } from '@/plugins/sockets'

export default {
  props: {
    id_fecha: String,
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        if (this.id_fecha != '2000-01-01') {
          setTimeout(() => {
            //alert("modificarComandaAll")
            this.ConsultarDatos(false)
          }, 2000)
        }
      }
    },

    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        if (this.id_fecha != '2000-01-01') {
          setTimeout(() => {
            //alert("modificarComandaAll")
            this.ConsultarDatos(false)
          }, 2000)
        }
      }
    },
  },
  setup(props) {
    let counterConsultas = ref(0)
    const datos = ref({})
    onBeforeMount(() => {
      setTimeout(() => {
        ConsultarDatos(true)
      }, 1000)
    })
    watch(props, () => {
      if (props.id_fecha != '2000-01-01') {
        setTimeout(() => {
          ConsultarDatos(false)
        }, 2000)
      }
    })
    /* socket.on('modificar-comanda-all', (...args) => {
      setTimeout(() => {
        ConsultarDatos(false)
      }, 2000)
    })*/

    const CerrarDia = () => {
      Vue.swal({
        html: '<h3>¿Seguro que quiere cerrar el dia actual? </h3>',
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          return MaestroService.CerrarDiaActual({ id_empleado: store.state.user.id_empleado })
            .then(response => {
              if (response.data.mensaje == 'DIA CERRADO CON EXITO') {
                Vue.swal({
                  icon: 'success',
                  title: `Exito`,
                  text: response.data.mensaje,
                })
              } else {
                Vue.swal({
                  icon: 'info',
                  title: `Oops...`,
                  text: response.data.mensaje,
                })
              }
              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })

              return false
            })
        },
        allowOutsideClick: () => {
          if (!Vue.swal.isLoading()) {
            Vue.swal.close()
          }
        },
      }).then(result => {})
    }

    const ConsultarDatos = (actualizar = false) => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.reporteCantidadComandas({ id_fecha: props.id_fecha })
          .then(response => {
            console.log(response.data)
            datos.value = response.data.datos
          })
          .catch(error => {
            console.error(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                        ...error, 
                        response: error?.response?.data
                    },
              funcion: 'ConsultarDatos',
            })
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }

    return {
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiCheckboxBlankCircle,
      },
      datos,
      CerrarDia,
      stateSocket,
      ConsultarDatos,
    }
  },
}
</script>

 

