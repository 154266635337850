
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-simple-table dense height="300" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-uppercase">Comanda</th>
                <th class="text-left text-uppercase">Mesonero</th>
                <th class="text-left text-uppercase">Resposables</th>
                <th class="text-left text-uppercase" colspan="2">Producto</th>
                <th class="text-left text-uppercase">Motivo</th>
                <th class="text-left text-uppercase">ver</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datos" :key="index">
                <td>
                  <small> {{ item.id_comanda }}</small>
                  <br />
                  <small> {{ funciones.formatoFecha(item.fecha_anulacion_detalle, 2) }}</small>
                </td>
                <td>
                  <small> {{ item.mesonero }}</small>
                </td>
                <td>
                  <small> {{ item.empleado_anula }}</small>
                </td>
                <td>
                  <v-avatar
                    :color="item.img ? '' : 'primary'"
                    :class="item.img ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img v-if="item.img" :src="`${config.api_imagenes}/assets/images/product/${item.img}`"> </v-img>
                    <span v-else>{{ item.nombre_producto.slice(0, 2).toUpperCase() }}</span>
                  </v-avatar>
                </td>
                <td>
                  <small>{{ item.nombre_producto }}</small>
                  <br />
                  <small>{{ item.nombre_puesto }}</small>
                </td>
                <td>
                  <small>{{ item.motivo_anulacion_detalle }}</small>
                </td>
                <td>
                  <v-btn icon small @click="AbrirModalComanda(item)">
                    <v-icon color="info" small>{{ icons.mdiMagnify }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <modal-ver-comanda
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    ></modal-ver-comanda>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import { mdiMagnify } from '@mdi/js'
import ModalVerComanda from './ModalVerComanda.vue'
import config from '@/api/config'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  props: {
    id_fecha: String,
    id_puesto: {
      type: Number,
      default: -1,
    },
  },
  components: {
    ModalVerComanda,
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        if (this.id_fecha != '2000-01-01') {
          setTimeout(() => {
            //alert("modificarComandaAll")
            this.cargarDatos(false)
          }, 5000)
        }
      }
    },
  },
  setup(props) {
    const datos = ref([])
    const openModalComanda = ref(false)
    const comandaSelect = ref(null)
    const AbrirModalComanda = datos => {
      openModalComanda.value = true
      comandaSelect.value = { id: datos.id_comanda }
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }

    let counterConsultas = ref(0)
    /*
    socket.on('modificar-comanda-all', (...args) => {
   
      setTimeout(() => {
        cargarDatos(false)
      }, 4000)
    })*/
    watch(props, () => {
      if (props.id_fecha != '2000-01-01') {
        
        setTimeout(() => {
            //alert("modificarComandaAll")
            cargarDatos(false)
          }, 5000)
      }
    })
    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.rptProductoAnulados({ id_fecha: props.id_fecha, id_puesto: props.id_puesto })
          .then(response => {
            datos.value = response.data.datos
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }

    return {
      datos,
      store,
      funciones,
      AbrirModalComanda,
      openModalComanda,
      GetOpenModalComanda,
      comandaSelect,
      config,
      cargarDatos,
      icons: {
        mdiMagnify,
      },
      stateSocket,
    }
  },
}
</script>
