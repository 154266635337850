<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        solo
        :clearable="clearable"
        v-model="date"
        :label="label"
        :rules="rules"
        :dense="dense"
        :outlined="outlined"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :allowed-dates="disabledAfterToday"
      :events="functionEvents"
      :type="type"
      v-model="date"
      scrollable
      locale="ES-ve"
    >
      <row>
        <v-col cols="12" class="my-0 mx-0 py-0 px-0">
          <small v-if="!funciones.fechasHorarioOficina()">
            Esta fuera del horario de oficina no podra visualizar historial antes del:
            {{ funciones.configuracionSelect(13) }}</small
          >
        </v-col>
        <v-col cols="12">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false"> Cancelar </v-btn>
          <v-btn text color="primary" @click="select(date)"> OK </v-btn>
        </v-col>
      </row>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import MaestroService from '@/api/servicios/MaestroService'
import funciones from '@/funciones/funciones'
import store from '@/store'
export default {
  props: {
    value: {
      type: Object,
      default: {
        fecha: '2000-01-01',
        grupo: [],
      },
    },
    dense: Boolean,
    outlined: Boolean,
    label: String,
    rules: Array,
    clearable: Boolean,
    type: {
      type: String,
      default: 'date',
    },
  },
  setup(props, contex) {
    const modal = ref(false)
    const date = ref(null)
    const dialog = ref(null)
    const diasCerrados = ref([])
    const diasMovimietos = ref([])
    const diasMovimietosDinero = ref([])
    const dias = ref([])
    onBeforeMount(() => {
      date.value = props.value.fecha
      //dialog.value.save(date.value)
    })

    watch(date, () => {
      if (date.value == null || date.value == undefined) {
        contex.emit('input', null)
      }
    })

    const functionEvents = date => {
      let array = []
      if (diasCerrados.value.includes(moment(date).format('YYYY-MM-DD'))) {
        array.push('warning')
      } else {
        if (diasMovimietosDinero.value.includes(moment(date).format('YYYY-MM-DD'))) {
          array.push('error')
        }
      }

      if (diasMovimietos.value.includes(moment(date).format('YYYY-MM-DD'))) {
        array.push('info')
      }

      return array
    }

    const disabledAfterToday = date => {
      const today = moment()
      if (!funciones.fechasHorarioOficina()) {
        return moment(date) < today && moment(date) >= moment(funciones.configuracionSelect(13))
      }
      return moment(date) < today
    }
    const ArrayDiasCerrados = () => {
      MaestroService.ArrayDiasCerrados()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            diasCerrados.value = response.data.datos.arreglo.split(',')
            diasMovimietos.value = response.data.datos?.arreglo_movimientos?.split(',')
            diasMovimietosDinero.value = response.data.datos.arreglo_movimientos_dinero.split(',')
            dias.value = response.data.datos.fechas
            select(response.data.datos.actual)
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'ArrayDiasCerrados',
          })
        })
    }

    onBeforeMount(() => {
      ArrayDiasCerrados()
    })

    watch(props, () => {
      if (props.value.fecha) {
        date.value = props.value.fecha
        dialog.value.save(date.value)
      }
    })

    const select = dato => {
      dialog.value.save(dato)
      const fecha_ = dias.value.find(it => it.fecha == dato)
      console.log('fecha_', dato)
      console.log('fecha_', fecha_)
      console.log('fecha_', dias.value)
      contex.emit('input', fecha_)
    }
    return {
      modal,
      date,
      select,
      dialog,
      diasCerrados,
      functionEvents,
      diasMovimietos,
      diasMovimietosDinero,
      disabledAfterToday,
      funciones,
      moment,
    }
  },
}
</script>