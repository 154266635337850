<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px" dark>

            <v-card>
                <v-card-title>
                    <span class="text-h5">Validar Login</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row">
                        <div class="mx-auto">
                            <v-img width="220" height="100%" src="@/assets/images/pages/candado1.png"></v-img>
                        </div>
                        <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
                        <div>
                            <v-container>
                                <v-row>

                                    <v-col cols="12">
                                        <v-text-field :disabled="datos.usuario != ''"  autofocus label="Usuario" :value="datos.usuario" v-model="usuario" required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Clave*" autofocus ref="claveRef" type="password"  v-on:keyup.enter="validar()"  required v-model="clave">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                         <small>{{mensaje}}</small> 
                                    </v-col>

                                </v-row>
                            </v-container>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="GetCancelar()">
                        Cerrar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="validar()" :disabled="validando">
                        Validar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  <script>
import { ref, watch } from '@vue/composition-api'
import SeguridadService from '@/api/servicios/SeguridadService'
    export default {
        props:{
            abrirModal: Boolean,
            datos: Object
        },
      setup(props,context) {
        const dialog = ref(false)
        const usuario = ref("")
        const clave = ref("")
        const claveRef = ref(null)
        const validando = ref(false)
        const mensaje = ref("")
        watch(props,()=>{
            if (props.abrirModal != dialog.value) {
                dialog.value = props.abrirModal
                usuario.value = props.datos.usuario
                clave.value = "" 
                mensaje.value = ""
            }
        })

        watch(dialog, ()=>{
            context.emit('GetAbrirModal', dialog.value) 
        })

        const validar = ()=>{
            validando.value = true
            mensaje.value = ""
            SeguridadService.validarCredencialesAccionModulo({ usuario: usuario.value, clave: clave.value, id_accion_modulo: props.datos.id_accion_modulo })
              .then(response => { 
                console.log(response.data)
              
                if (response.data.datos == 'ok') {
                    
                    context.emit('GetResultado', response.data.datos) 
 
                } else {
                    mensaje.value = "Usuario o clave inavalida, o el usuario no tiene permiso" 
                }  
              })
              .catch(error => {
                mensaje.value  = error
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'validar',
                })
              }).finally(datos=>{
                validando.value = false
              })
        } 

       const GetCancelar = () => {
        dialog.value = false
         context.emit('GetCancelar', dialog.value)
       }


        return {
            dialog,
            usuario,
            clave,
            claveRef,
            validar,
            mensaje,
            validando,
            GetCancelar
        }
        
      }
    }
  </script>