<template>
    <div>
        <v-dialog v-model="dialog" scrollable max-width="800px">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Transferir Comandas
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col lg="6">
                                <v-subheader>Comanda #{{comanda.id}} {{comanda.cliente}}</v-subheader>

                                <v-list flat subheader style="height: 300px; overflow-y: auto;">
                                    <v-list-item-group active-class="">
                                        <v-list-item v-for="(item,index) in comanda.detalles" :key="index">
                                            <template>

                                                <v-list-item-icon>
                                                    <v-badge bordered top color="primary" offset-x="10" offset-y="10"
                                                        :content="item.cantidad">
                                                        <v-avatar size="30">
                                                            <v-img
                                                                :src="`${config.api_imagenes}/assets/images/product/${item.img}`">
                                                            </v-img>
                                                        </v-avatar>
                                                    </v-badge>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title><small>{{ item.nombre_producto }}</small>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-list-item-action-text>
                                                        <strong>{{ round(item.total_iva).toFixed(2) }}$</strong>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>

                            </v-col>
                            <v-col lg="6">
                             
                                <v-autocomplete
                                    :items="mesoneros"
                                    v-model="mesonero" item-text="nombre_completo" item-value="id_empleado"
                                    label="Mesonero">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <span>Total: {{round(comanda.total).toFixed(2)}}$</span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="Transferir()"
                        :disabled="mesonero == null || transfiriendo == true">
                        Transferir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <modal-validar-login-vue :datos="ValidarLoginDatos" :abrirModal="ValidarLogin"
            @GetAbrirModal="GetAbrirModalValidarLogin" @GetResultado="GetResultadoValidarLogin"
            @GetCancelar="GetCancelarModalValidarLogin">
        </modal-validar-login-vue>
    </div>
</template>
  <script>
    
    import { 
        mdiCallSplit,
   
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config' 
import SeguridadService from '@/api/servicios/SeguridadService'
import store from '@/store'
import ModalValidarLoginVue from '@/views/sistema/seguridad/ModalValidarLogin.vue'
import ComandaService from '@/api/servicios/ComandaService'
import Vue from 'vue'
    export default {
        components:{
            ModalValidarLoginVue
        },
        props: {
            comanda: Object, 
            color: String,
            modalTransferir: Boolean
        },
        setup(props,context) {
        const settings    = ref(null)
        const detalles = ref([])
        const total  = ref(0)
        const dialog = ref(false)
        const mesoneros = ref([])
        const mesonero = ref(null)
        const ValidarLogin = ref(false)
        const ValidarLoginDatos = ref({})
        const transfiriendo = ref(false)

        const GetAbrirModalValidarLogin =(datos) => {
            ValidarLogin.value = datos
          
        }
        const GetCancelarModalValidarLogin =() => {
            transfiriendo.value = false 
          
        }
        const GetResultadoValidarLogin =(datos) => {
            ValidarLogin.value = false
             
            ComandaService.transferirComanda({comanda : props.comanda.id, mesonero: mesonero.value, id_empleado : store.state.user.id_empleado})
            .then(response => {
               
                if (response.data.mensaje == 'transferido con exito') { 
                    
                    props.comanda.id_empleado_mesonero = mesonero.value
                    props.comanda.id_mesonero = mesonero.value
                    dialog.value = false
                    context.emit('GetModalTransferir', false)
                     
                }else{
                
                    store.commit('setAlert', {
                                message: "hubo un error al momento de transferir",
                                type: 'info',
                                })
                }
            })
            .catch(error => {
                    console.log(error)
            }).finally(datos =>{
                transfiriendo.value = false 
            })
           
        }

        const Transferir = () => { 
                transfiriendo.value = true
                const mesoneroSelect = mesoneros.value.filter(item=>(item.id_empleado == mesonero.value))[0]
                console.log(mesoneroSelect)
                ValidarLogin.value = true

                GetResultadoValidarLogin()
             /*   ValidarLoginDatos.value =  {...mesoneroSelect, id_accion_modulo:0} 
                let respuesta = {} */
 
        }
 

        watch(props,()=>{
            
            if (props.modalTransferir != dialog.value) {
                dialog.value = props.modalTransferir
            }
            if (dialog.value == true) {
                  mesonero.value  = null
                  transfiriendo.value = false
                SeguridadService.listarMesonerosLogin()
                .then(response => {
                    console.log(response)
                    if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                      //  mesoneros.value =response.data.datos 
                    } mesoneros.value =response.data.datos.filter(item=>(item.id_empleado != store.state.user.id_empleado)) 
                })
                .catch(error => {
                     console.log(error)
                })
                }
 
        })
        watch(dialog, ()=>{
            
            context.emit('GetModalTransferir', dialog.value)
            if (dialog.value == true ) {
                settings.value = null
            }
        })
 

        function round(num) {
            var m = Number((Math.abs(num) * 100).toPrecision(15))
            return (Math.round(m) / 100) * Math.sign(num)
            }
        return {
            icons:{
                mdiCallSplit
            },
            dialog,
            settings,
            detalles,
            config,
            round,
            total,  
            mesonero,
            mesoneros,
            store,
            Transferir,
            ValidarLogin,
            GetAbrirModalValidarLogin,
            GetResultadoValidarLogin,
            ValidarLoginDatos,
            transfiriendo,
            GetCancelarModalValidarLogin
        }
      },
    }
  </script>