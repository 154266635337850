<template>
  <v-card
    :color="color"
     @click="eventoClick()"
  > 
    <v-card-text  class="text-no-wrap white--text text--primary mt-3">
      <p class="font-weight-semibold text-sm mb-1 white--text">
        {{ statTitle }}
      </p>

      <div class="d-flex align-end flex-wrap white--text">
        <span class="font-weight-semibold text-2xl me-1 mb-2">{{ statistics }}</span>
        <span
          class="percentage text-xs mb-2 white--text"
          :class="checkChange(change) ? 'success--text':'error--text'"
        > {{ change }}</span>
      </div>
      <p class="text-xs t  mb-0 white--text">
        {{ subtitle }}
      </p>
    </v-card-text>
    
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    statistics: null,
    change: { type: String, default: '' },
    click:Boolean
  },
  setup(props,context) {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    const eventoClick = ()=>{
      context.emit("eventoClick",{})
    }

    return {
      mdiDotsVertical,
      checkChange,
      eventoClick
    }
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
