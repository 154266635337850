
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-select
          v-if="id_puesto == -1"
          hide-details=""
          class="mx-4"
          v-model="puesto_select"
          :items="puestos"
          label="Locatario/Area"
          item-text="nombre"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-simple-table dense height="250" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-uppercase">Producto</th>
                <th class="text-right text-uppercase">Precio</th>
                <th class="text-right text-uppercase">Cantidad</th>
                <th class="text-right text-uppercase">TotalVenta</th>
                <th class="text-right text-uppercase" v-if="!(id_puesto == -1)">TotalCosto</th>
                <th class="text-right text-uppercase" v-if="!(id_puesto == -1)">Ganancia</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datos" :key="index">
                <td>
                  {{ item.nombre }}
                </td>
                <td class="text-right">$.{{ funciones.formatoNumeric(item.precio_real) }}</td>
                <td class="text-right">{{ item.cant }}</td>
                <td class="text-right">$.{{ funciones.formatoNumeric(item.total) }}</td>
                <td class="text-right" v-if="!(id_puesto == -1)">$.{{ funciones.formatoNumeric(item.costo) }}</td>
                <td class="text-right" v-if="!(id_puesto == -1)">
                  $.{{ funciones.formatoNumeric(item.total - item.costo) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-divider class="mb-2"></v-divider>

        $.{{ funciones.formatoNumeric(total) }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import PuestoService from '@/api/servicios/PuestoService'
import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'
export default {
  props: {
    id_fecha: String,
    id_grupo: Number,
    id_puesto: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.cargarDatos(false)
        }, 11000)
      }
    },
  },
  setup(props) {
    const datos = ref([])

    let counterConsultas = ref(0)
    const puesto_select = ref(null)
    onBeforeMount(() => {
      if (props.id_puesto > -1) {
        puesto_select.value = props.id_puesto
      }
      setTimeout(() => {
        cargarPuestos()
      }, 1000)
    })
    watch(props, () => {
      setTimeout(() => {
      cargarDatos(false)
      cargarPuestos()
      if (props.id_puesto > -1) {
        puesto_select.value = props.id_puesto
      }
    }, 11000)
    })

    watch(puesto_select, () => {
     
          cargarDatos(false)
      
    })

    const total = ref(0)
    const totalizar = () => {
      total.value = 0
      datos.value?.forEach(item => {
        total.value += item.total
      })
    }

    /*socket.on('modificar-comanda-all', (...args) => {
      setTimeout(() => {
        cargarDatos(false)
      }, 8000)
    })*/
    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.reporteVentasPorPuesto({ id_fecha: props.id_fecha, id_puesto: puesto_select.value })
          .then(response => {
            datos.value = response.data.datos
            totalizar()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }
    const puestos = ref([])

    const cargarPuestos = () => {
      PuestoService.obtenerPuestos()
        .then(response => {
          puestos.value = response.data.datos
          cargarDatos(true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {})
    }

    return {
      datos,
      store,
      puesto_select,
      puestos,
      total,
      funciones,
      cargarDatos,
      stateSocket,
    }
  },
}
</script>
