<template>
  <v-container>
    <v-row class="py-0 my-0" >
      <v-col  cols="12" sm="6" md="6" class="py-0 my-0">
        
        <DatePicker v-model="fechaSelectObj" :dense="true" :outlined="true" />
   
      </v-col>
      <v-col cols="12" sm="6" md="6"  class="py-0 my-0">
        <v-select
          class="py-0 my-0"
          :items="fechaSelectObj.grupos"
          label="Grupo"
          item-text="descripcion"
          item-value="id_grupo_negocio"
          v-model="gruposSelect"
          dense
          solo
          v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_FECHAS)"
        ></v-select>
      </v-col>
    </v-row>
    <indicadores-generales
      :id_grupo="gruposSelect"
      :id_fecha="fechaSelectObj.fecha"
      v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_INDICADORES)"
    ></indicadores-generales>
    <v-row>
      <v-col lg="4" md="12" sm="12">
        <v-row>
          <v-col lg="12" md="6" cols="12">
            <cerrar-dia
              :id_fecha="fechaSelectObj.fecha"
              v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CIERRE_DIA)"
            ></cerrar-dia>
          </v-col>

          <v-col lg="12" md="6" cols="12" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_TASA)">
            <card-tasa></card-tasa>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="8" md="12" sm="12" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CAJA)">
        <v-card>
          <div>
            <v-card-title> Arqueo de cajas </v-card-title>
            <div style="height: 300px; overflow-y: auto">
              <tabla-caja :id_fecha="fechaSelectObj.fecha"></tabla-caja>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col lg="12" md="12" sm="12"  >
        <v-card>
          <v-card-subtitle> Relacion Caja Grupo Negocio </v-card-subtitle>
          <tabla-relacion-caja-grupo  :fecha_desde="fechaSelectObj.fecha" :fecha_hasta="fechaSelectObj.fecha" :id_grupo="gruposSelect"> </tabla-relacion-caja-grupo>
        </v-card>
      </v-col>
      <v-col lg="12" md="12" sm="12" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CAJA)">
        <v-card>
          <v-card-subtitle> Totalizado tipo pago </v-card-subtitle>
          <tabla-total-cajas-forma-pago :id_fecha="fechaSelectObj.fecha" :id_grupo="gruposSelect"></tabla-total-cajas-forma-pago>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <tabla-comanda :id_fecha="fechaSelectObj.fecha"></tabla-comanda>
      </v-col>

      <v-col lg="12" md="12" sm="12" class="mt-4" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CAJA)">
        <v-card>
          <v-card-subtitle> Total locatario/area por forma pago</v-card-subtitle>
          <TablaPuestosFormasPago :id_fecha="fechaSelectObj.fecha" :id_grupo="gruposSelect"></TablaPuestosFormasPago>
        </v-card>
      </v-col>
      <v-col lg="6" md="12" sm="12" class="mt-4" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CAJA)">
        <v-card>
          <v-card-subtitle> Ventas por locatario/area</v-card-subtitle>
          <TablaVentasPorPuesto :id_fecha="fechaSelectObj.fecha" :id_grupo="gruposSelect"></TablaVentasPorPuesto>
        </v-card>
      </v-col>
      <v-col lg="6" md="12" sm="12" class="mt-4" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CAJA)">
        <v-card>
          <v-card-subtitle> Ventas por producto x caja</v-card-subtitle>
          <TablaVentasPorProductoCaja :id_fecha="fechaSelectObj.fecha" :id_grupo="gruposSelect"></TablaVentasPorProductoCaja>
        </v-card>
      </v-col>
      <v-col lg="12" md="12" sm="12" class="mt-4" v-if="validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER_CAJA)">
        <v-card>
          <v-card-subtitle> Productos Anulados</v-card-subtitle>
          <TablaItemsAnulados :id_fecha="fechaSelectObj.fecha" :id_grupo="gruposSelect"></TablaItemsAnulados>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import TablaCaja from './componentes/TablaCaja.vue'
import CardTasa from './componentes/CardTasa.vue'
import CerrarDia from './componentes/CerrarDia.vue'
import TablaRelacionCajaGrupo from './componentes/TablaRelacionCajaGrupo.vue'
import TablaComanda from './componentes/TablaComanda.vue'
import IndicadoresGenerales from './componentes/IndicadoresGenerales.vue'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { acciones } from '@/modulos'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import TablaTotalCajasFormaPago from './componentes/TablaTotalCajasFormaPago.vue'
import TablaVentasPorPuesto from './componentes/TablaVentasPorPuesto.vue'
import TablaVentasPorProductoCaja from './componentes/TablaVentasPorProductoCaja.vue'
import TablaPuestosFormasPago from './componentes/TablaPuestosFormasPago.vue'
import TablaItemsAnulados from './componentes/TablaItemsAnulados.vue'
import DatePicker from '@/components/DatePickerVentas.vue' 
// demos

export default {
  components: {
    StatisticsCardVertical,
    TablaCaja,
    CardTasa,
    CerrarDia,
    TablaRelacionCajaGrupo,
    TablaComanda,
    IndicadoresGenerales,
    TablaTotalCajasFormaPago,
    TablaVentasPorPuesto,
    TablaVentasPorProductoCaja,
    TablaPuestosFormasPago,
    TablaItemsAnulados,
    DatePicker,
  },
  setup() {
    onBeforeMount(() => {
      CargarFechas()
    })
    const fechas = ref([])
    const fechaSelect = ref(999999)
    const fechaSelectObj = ref({
      fecha: '2000-01-01',
      grupos: [],
    })
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }

    const grupos = ref([])
    const gruposSelect = ref(1)

    watch(fechaSelect, () => {
      fechas.value.forEach(item => {
        if (item.id == fechaSelect.value) {
          grupos.value = item.grupos
        }
      })
    })

    const CargarFechas = () => {
      MaestroService.reporteDatosFechas()
        .then(response => {
          fechas.value = response.data.datos
        })
        .catch(error => {
          store.commit('setAlert', {
          message: {
                        ...error, 
                        response: error?.response?.data
                    },
          type: 'error',
          
          error: error,
            funcion: 'CargarFechas',
        })
        })
        .finally(item => {
          fechaSelect.value = fechas.value[0].id
          grupos.value = fechas.value[0].grupos
        })
    }

    const totalProfit = {
      statTitle: 'Ventas general',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Sin 10%: $9,384.27',
      statistics: '$9,953.25',
      change: '',
    }

    const totalSales = {
      statTitle: 'Comandas pagadas',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Por Pagar: $0.00',
      statistics: '$9,953.25',
      change: '',
    }

    // vertical card options
    const newProject = {
      statTitle: 'Servicio',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: '% Evitado: $369.45',
      statistics: '$568.9',
      change: '',
    }

    const salesQueries = {
      statTitle: 'Balance General',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Pagado: $5,568.39',
      statistics: '$5,568.39',
      change: '',
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
      fechaSelect,
      fechas,
      grupos,
      gruposSelect,
      validarAcciones,
      acciones,
      fechaSelectObj
    }
  },
}
</script>
