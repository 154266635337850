
<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-uppercase">Moneda</th>
            <th class="text-left text-uppercase">Tipo pago</th>
            <th class="text-right text-uppercase" v-for="(item, index) in cabecera" :key="index">
              {{ item.value }}
            </th>
            <th class="text-right text-uppercase" v-if="cabecera.length > 1">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item2, index) in datos" :key="index">
            <td class="text-left">
              {{ item2.moneda_nombre }}
            </td>
            <td class="text-left">
              {{ item2.tipo_pago_nombre }}
            </td>
            <td class="text-right" v-for="(item, index) in cabecera" :key="index">
              <span :class="item2[item.value] < 0 ? '' : ''"
                >{{ item2.simbolo }} {{ item2[item.value].toFixed(2) }}</span
              >
            </td>
            <td class="text-right" v-if="cabecera.length > 1">
              {{ item2.pago_moneda.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td colspan="2">TOTAL</td>
            <td class="text-right" v-for="(item, index) in cabecera" :key="index">
              <span> $ {{ item.total.toFixed(2) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'

import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'

export default {
  props: {
    fecha_desde: String,
    fecha_hasta: String,
    id_grupo: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.cargarDatos(false)
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.cargarDatos(false)
        }, 8000)
      }
    },
  },
  setup(props) {
    const datos = ref([])
    const cabecera = ref([])
    let counterConsultas = ref(0)

    onBeforeMount(() => {
      setTimeout(() => {
        cargarDatos(true)
      }, 6000)
    })
    watch(props, () => {
      setTimeout(() => {
          //alert("modificarComandaAll")
          cargarDatos(false)
        }, 8000)
    })
    /*
    socket.on('modificar-comanda-all', (...args) => {
 
      setTimeout(() => {
        cargarDatos(false)
      }, 6000)
    })*/
    const cargarDatos = actualizar => {
      if (props.id_fecha != '2000-01-01') {
        MaestroService.ReporteRelacionCajaPuestoGrupoNew({
          id_grupo_negocio: props.id_grupo,
          fecha_desde: props.fecha_desde,
          fecha_hasta: props.fecha_hasta,
        })
          .then(response => {
            cabecera.value = []
            datos.value = response.data.datos.datos
            response.data.datos.columnas.split(',').forEach(col => {
              let _total = 0
              datos.value.forEach(el => {
                _total += el[col + '_REAL']
              })
              cabecera.value.push({ text: funciones.camelCase(col.replace(/_/g, ' ')), value: col, total: _total })
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            if (actualizar == true) counterConsultas.value += 1
          })
      }
    }

    return {
      cabecera,
      datos,
      cargarDatos,
      stateSocket,
    }
  },
}
</script>
